<template lang="pug">
.row.bg-secondary.text-center.q-py-sm.q-px-md.cookies(v-if="isShow")
    q-btn.q-mr-sm.absolute-top-right(
        flat
        round
        fab-mini
        v-close-popup
        @click="closePlash")
        q-icon(
            name="etabl:cross"
            size="16px"
            color="positive")
    .text-left
        span.line-height-20.text-left 
            | Продолжая находиться  на сайте, Вы <br> соглашаетесь с тем, что мы <br> используем cookie-файлы. 
        router-link.link(to="/policy" title="Подробнее")
            | Подробнее
    .q-mt-sm.flex.justify-between.row(style="width: 100%")
        q-img(
            alt="cookie"
            title="Управление cookie-файлами"
            src="icons/cookie.svg" 
            no-spinner
            width="52px"
            height="40px"
        )
        .col-7
            base-btn(
                label="Принять"
                height="34"
                bradius="16"
                @click="closePlash"
            )
</template>

<script>
import { LocalStorage } from 'quasar';
import { ref, onMounted } from 'vue';

import BaseBtn from 'components/Base/BaseBtn.vue';

export default {
    name: 'Plashka',
    components: {
        BaseBtn,
    },
    setup() {
        const isShow = ref(false);

        const closePlash = () => {
            isShow.value = false;
            LocalStorage.set('COOKIES_ACCEPT', true);
        };

        onMounted(() => {
            if(!LocalStorage.getItem('COOKIES_ACCEPT')) {
                isShow.value = true;
            }
        });

        return {
            isShow,
            closePlash,
        };
    },
};
</script>

<style lang="scss" scoped>
.cookies {
    z-index: 2002;
    height: 132px;
    width: 340px;
    position: fixed;
    bottom: 70px;
    left: 70px;
    border-radius: 16px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);

    @media screen and (max-width: $breakpoint-mobile) {
        width: calc(100% - 32px);
        left: 16px;
    }
    @media screen and (max-width: 326px) {
        height: 146px;
    }
}
</style>
