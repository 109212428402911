<template lang="pug">
q-btn.full-width(
    unelevated
    :outline="outline"
    :color="color"
    :no-caps="!caps"
    :loading="loading"
    :disable="disable"
    :style="styles"
    :class="[`font-${font_size}`, { 'thin': thin, 'text-weight-medium': !thin, 'gradient': gradient }, background, noPadding ? 'no-padding' : '']"
    @click="goTo(link)")
    template(v-slot:loading)
        q-spinner(
            :thickness="5"
            :color="loading_color")
    template(v-if="icon")
        .col-12.row.justify-center
            span.col-sm-3.col-xs-4.flex.items-center.justify-center(
                :class="{ 'justify-end': !centred }"
            ).q-pl-md
                q-icon(
                    :name="`etabl:${icon}`"
                    :color="icon_color || secondary"
                    :size="icon_size"
                )
            span.col-sm-9.col-xs-8.btn__label(
                :class="[`text-${text_color || secondary}`, { 'text-left q-pl-md': !centred }]"
            )
                | {{ label }}
    template(v-else)
        div(
            :class="[`text-${text_color || secondary}`]"
            :style="[`font-size: ${font_size}px;`, subLabel ? 'line-height: 0;' : '']")
            | {{ label }}
        .full-width.text-center.font-12(    
            v-if="subLabel"
            :class="[`text-${text_color || secondary}`]"
            :style="subLabel ? 'line-height: 0;' : ''")
            | {{ subLabel }}
    slot(name="info")
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

export default {
    name: 'BaseBtn',
    props: {
        label: {
            type: String,
            required: true,
        },
        subLabel: {
            type: String,
        },
        color: {
            type: String,
            default: () => 'positive',
        },
        icon: {
            type: String,
            default: () => '',
        },
        caps: {
            type: Boolean,
            default: () => false,
        },
        disable: {
            type: Boolean,
            default: () => false,
        },
        loading: {
            type: Boolean,
            default: () => false,
        },
        font_size: {
            type: String,
            default: () => '14',
        },
        icon_size: {
            type: String,
            default: () => 'sm',
        },
        centred: {
            type: Boolean,
            default: () => false,
        },
        width: {
            type: String,
            default: () => '',
        },
        height: {
            type: String,
            default: () => '44',
        },
        outline: {
            type: Boolean,
            default: () => false,
        },
        text_color: {
            type: String,
            default: () => '',
        },
        icon_color: {
            type: String,
            default: () => '',
        },
        thin: {
            type: Boolean,
            default: () => false,
        },
        gradient: {
            type: Boolean,
            default: () => false,
        },
        bradius: {
            type: String,
            default: () => '10',
        },
        link: {
            type: String,
            default: () => '',
        },
        border: {
            type: String,
            default: () => 'inherit',
        },
        background: {
            type: String,
            default: () => '',
        },
        backgroundHEX: {
            type: String,
            default: () => '',
        },
        loading_color: {
            type: String,
            default: () => 'positive',
        },
        lineHeight: {
            type: String,
            default: () => '',
        },
        noPadding: {
            type: Boolean,
            default: () => false,
        },
    },
    setup(props) {
        const router = useRouter();
        const secondary = computed(() => props.color === 'secondary' ? 'primary' : 'secondary');

        const styles = computed(() => {
            return {
                width: `${props.width}px !important`,
                height: `${props.height}px`,
                borderRadius: `${props.bradius}px`,
                border: props.border,
                lineHeight: `${props.lineHeight} !important`,
                backgroundColor: `${props.backgroundHEX} !important`,
            };
        });

        const goTo = function (link) {
            if (link) {
                if (link.startsWith('http') && process.env.CLIENT) {
                    document.location.href = link;
                } else {
                    router.push(link);
                }
            }
        };

        return {
            secondary,
            styles,
            goTo,
        };
    },
};
</script>

<style lang="scss" scoped>
.q-btn {
    border-radius: $button-border-radius;
    padding: 0;
    min-height: 16px;
    transition: $main-transition;

    :deep &__content {
        padding: 4px 0;
    }
}

.no-padding :deep(.q-btn__content) {
    padding: 0 !important;
}

.q-btn :deep(.q-btn__wrapper:before) {
    border: inherit;
    border-radius: inherit;
}

.q-btn.thin :deep(.q-btn__wrapper:before) {
    border: 1px solid $grey-13;
    border-radius: inherit;
}

.q-btn.gradient {
    border: none;
    background: $lime !important;
}

.q-btn.gradient :deep(.q-btn__wrapper:before) {
    border: 0px solid $grey-13;
}

.q-btn :deep(.q-btn__wrapper) {
    min-height: 1em;
    padding: 0;
}

.btn__label {
    @media screen and (max-width: $breakpoint-mobile) {
        padding-left: 0;
    }
}
</style>
